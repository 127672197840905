<template>
  <v-container fluid class="px-auto">
    <v-text-field
      v-model="name"
      label="Name der Tabelle"
      filled>
    </v-text-field>
    <v-textarea
      name="csv"
      filled
      label="Gib das CSV hier ein:"
      auto-grow
      v-model="csv"
    ></v-textarea>
    <v-btn 
      :disabled="csv === '' || name === ''"
      class="mx-auto mb-5"
      color="primary"
      @click="dataSourceString=convert()">
      Konvertieren
    </v-btn>
    <v-textarea
      name="result"
      filled
      readonly
      label="Tabelle für die REST-API"
      auto-grow
      :disabled="dataSourceString === ''"
      v-model="dataSourceString"
    ></v-textarea>
  </v-container>
</template>

<script>

export default {
  name: 'CSV2DataSource',
  data: () => ({
    csv: '',
    name: '',
    dataSource: {
      Type: "StaticTable",
      Title: "",
      Columns:[],
      Values:[]
    },
    dataSourceString: ''
  }),
  methods: {
    convert(){
      this.dataSource.Columns = []
      this.dataSource.Values = []
      this.dataSource.Title = this.name
      let rows = this.csv.split('\n')
      let headlines = rows[0].split(';')
      for (const h of headlines) {
        console.log(h)
        this.dataSource.Columns.push({
          ColumnId: h,
          Title: "",
          DataType: "String"
        })
      }
      for (const r of rows.slice(1)){
        const row = r.split(';')
        var values = {}
        for (var i=0; i<row.length; i++) {
          values[headlines[i]] = row[i]
        }
        this.dataSource.Values.push(values)
      }
      return JSON.stringify(this.dataSource,null,4)
    }
  }
}
</script>